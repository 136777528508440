import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import StarRating from 'components/StarRating'
import { Flex, Box } from '@rebass/emotion'
import Avatar from 'components/Avatar'
import { css } from '@emotion/core'
import { SPACERS, COLORS, FONT_SIZES } from 'utils/styleHelpers'
import { NODE_TYPES, EVENT_TYPES, TRACKING_ACTIONS } from 'utils/constants'
import { PrimaryButtonLink } from 'components/PrimaryButton'
import { getLink } from 'utils/urlHelpers'
import { StrainCatLabel, StrainNameLabel, DescriptionLabel } from './styles'
import Truncate from 'react-truncate'
import LinkWrapper from 'components/LinkWrapper'
import { SessionContext } from 'components/AuthContext'
import track, { TrackingPropType } from 'react-tracking'
import { stampTime, eventDispatch } from 'utils/tracker'

function StrainCarouselSlide({ strain, tracking }) {
	const { name, id, category, description, averageRating, totalReviews, imageURL } = strain
	const strainUrl = getLink(strain, NODE_TYPES.strain)
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const trackingData = {
		action: TRACKING_ACTIONS.TOP_RATED_PRODUCT_SELECT_ACTION,
		event: EVENT_TYPES.WR_TOP_RATED_PRODUCT_SELECT,
		strainCategory: strain.category,
		nodeID: id,
		nodeType: NODE_TYPES.STRAINS,
		nodeLabel: name,
		sessionID,
		uid,
		userIp,
		userLocation,
		version,
	}
	return (
		<Flex
			css={css`
				padding: ${SPACERS.M};
			`}
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box
				css={css`
					margin: ${SPACERS.L};
				`}
			>
				<StrainCatLabel>{category}</StrainCatLabel>
			</Box>
			<Box
				css={css`
					margin: ${SPACERS.M};
				`}
				alignSelf="center"
			>
				<LinkWrapper to={strainUrl} tracker={trackingData}>
					<Avatar size={[200, 200]} src={imageURL} />
				</LinkWrapper>
			</Box>
			<Box
				css={css`
					padding-top: ${SPACERS.L};
					color: ${COLORS.GREEN};
				`}
				alignSelf="center"
			>
				Top Rated Product
			</Box>
			<LinkWrapper to={strainUrl} tracker={trackingData}>
				<Box>
					<StrainNameLabel>{name}</StrainNameLabel>
				</Box>
			</LinkWrapper>

			<LinkWrapper to={strainUrl} tracker={trackingData}>
				<Box alignSelf="center">
					<Flex flexDirection="row">
						<Box>
							<StarRating name="featuredDispenaryRating" starRatingId={id} value={averageRating} />
						</Box>
						<Box
							css={css`
								color: ${COLORS.YELLOW};
								font-size: ${FONT_SIZES.H5};
								font-weight: 500;
							`}
						>
							{averageRating} Stars out of {totalReviews} reviews
						</Box>
					</Flex>
				</Box>
			</LinkWrapper>
			<Box
				css={css`
					padding-top: ${SPACERS.M};
				`}
				alignSelf="center"
			>
				<Truncate
					css={css`
						color: ${COLORS.BLACK};
						font-size: ${FONT_SIZES.H5};
						transition: color 0.5s;
						.dark & {
							color: ${COLORS.WHITE};
						}
					`}
					lines={3}
					ellipsis={
						<span>
							... <Link to={strainUrl}>Read More</Link>
						</span>
					}
				>
					<DescriptionLabel dangerouslySetInnerHTML={{ __html: description }}></DescriptionLabel>
				</Truncate>
			</Box>
			<Box
				css={css`
					padding-top: ${SPACERS.XL};
					width: 100%;
				`}
			>
				<Flex flexDirection="row" justifyContent="space-around">
					<Box>
						<PrimaryButtonLink
							color={COLORS.RED}
							textcolor={COLORS.WHITE}
							to={strainUrl}
							state={{ activeTabIndex: 2 }}
							fontSize={FONT_SIZES.H5}
							onClick={() => tracking.trackEvent(trackingData)}
						>
							Locate Product
						</PrimaryButtonLink>
					</Box>
					<Box>
						<PrimaryButtonLink
							color={COLORS.BLACK}
							textcolor={COLORS.WHITE}
							bordercolordark={COLORS.SUPER_LIGHT_GRAY}
							fontSize={FONT_SIZES.H5}
							to={strainUrl}
							onClick={() => tracking.trackEvent(trackingData)}
						>
							Learn More
						</PrimaryButtonLink>
					</Box>
				</Flex>
			</Box>
		</Flex>
	)
}
StrainCarouselSlide.propTypes = {
	strain: PropTypes.shape({
		name: PropTypes.string,
		id: PropTypes.string,
		averageRating: PropTypes.number,
		totalReviews: PropTypes.number,
		category: PropTypes.string,
		description: PropTypes.string,
		imageURL: PropTypes.string,
	}),
	tracking: TrackingPropType,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainCarouselSlide)
