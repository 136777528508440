import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { COLORS, FONT_SIZES, SPACERS } from 'utils/styleHelpers'

export const Container = styled(Flex)`
	overflow: hidden;
	width: ${({ width }) => width}px;
`
export const Children = styled.div`
	width: ${({ width }) => width}px;
	position: relative;
	height: ${({ height }) => height}px;
`
export const DotIcon = styled(Box)`
	font-size: ${FONT_SIZES.H4};
	padding-left: ${SPACERS.S};
	padding-right: ${SPACERS.S};
	cursor: pointer;
	text-shadow: 1px 1px 1px #fff;
	user-select: none;
	color: ${({ index, position }) => (index === position ? COLORS.GREEN : COLORS.MEDIUM_LIGHT_GRAY)};
	transition: text-shadow 0.5s;
	.dark & {
		text-shadow: 1px 1px 1px ${COLORS.MEDIUM_GRAY};
	}
`
export const Dots = styled(Box)`
	width: ${({ width }) => width}px;
	z-index: 100;
	text-align: center;
`
export const DotLabel = styled.div`
    border 1px solid ${COLORS.GREEN};
    cursor: pointer;
    background: ${COLORS.GREEN};
    font-size: ${FONT_SIZES.H5};
    padding-right: 25px;
    padding-left: 25px;
    color: ${COLORS.WHITE};
    user-select: none;
    border-radius: 3px;
    margin-left: ${SPACERS.M};
    margin-right: ${SPACERS.M};
`
