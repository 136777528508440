import styled from '@emotion/styled'
import { COLORS, FONT_SIZES, SPACERS } from 'utils/styleHelpers'

export const StrainCatLabel = styled.span`
	background: ${COLORS.GREEN};
	border-radius: 3px;
	color: ${COLORS.WHITE};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	padding-left: ${SPACERS.L};
	padding-right: ${SPACERS.L};
`
export const StrainNameLabel = styled.span`
	color: ${COLORS.BLACK};
	font-size: ${FONT_SIZES.H1};
	font-weight: 600;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
`
export const DescriptionLabel = styled.span`
	font-size: ${FONT_SIZES.H7};
`
