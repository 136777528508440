/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Hero from 'components/Hero'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import GlobalLayout from 'components/GlobalLayout'
import { PrimaryButton, PrimaryButtonLink, PrimaryButtonAnchor } from 'components/PrimaryButton'
import { DispensaryLocator, PriceTag, StrainFinder } from 'components/Iconography'
import HomeCarousel from 'components/HomeCarousel'
import StrainCarouselLarge from 'components/StrainCarouselLarge'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'
import { MQ, SPACERS, COLORS, FONT_SIZES } from 'utils/styleHelpers'

import DispensaryCarouselSlide from 'components/DispensaryCarouselSlide'
import StrainCarouselSlide from 'components/StrainCarouselSlide'
import StrainCarouselSlideLarge from 'components/StrainCarouselSlideLarge'
import { Breakpoint } from 'react-socks'
import { sortByKey } from 'utils/arrayHelpers'
const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	z-index: 0;
	${MQ.M} {
		flex-wrap: nowrap;
	}
`
const ContentBoxCTA = styled(Box)`
	display: ${({ isHidden }) => (isHidden.length > 1 && isHidden[0] ? 'none' : 'inline')};
	background-color: ${({ color }) => color || COLORS.SUPER_LIGHT_GRAY};
	box-shadow: ${({ boxShadow }) => (boxShadow ? '-1px 9px 18px - 7px rgba(0, 0, 0, 0.75)' : 'none')};
	transition: background-color 0.5s, box-shadow 0.5s;
	padding-top: ${SPACERS.XL};
	padding-right: ${SPACERS.L};
	padding-left: ${SPACERS.L};
	padding-bottom: ${SPACERS.XL};
	.dark & {
		background-color: ${({ darkColor }) => darkColor || COLORS.BLACK};
		box-shadow: ${({ boxShadow }) => (boxShadow ? '-1px 9px 18px - 7px rgba(0, 0, 0, 0.75)' : 'none')};
		${MQ.L} {
			padding-top: ${SPACERS.L};
			padding-bottom: ${SPACERS.L};
			box-shadow: none;
		}
	}
	${MQ.L} {
		padding-top: ${SPACERS.L};
		padding-bottom: ${SPACERS.L};
		display: ${({ isHidden }) => (isHidden.length > 1 && isHidden[1] ? 'none' : 'inline')};
		box-shadow: none;
	}
`

const ContentValueProp = styled(Flex)`
	width: 100%;
	padding: ${SPACERS.S};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${MQ.L} {
		justify-content: flex-start;
		align-items: space-between;
		margin: auto;
		height: 300px;
	}
`
const ContentValuePropBox = styled.div`
	padding: ${SPACERS.L};
`
const IconBox = styled(Box)`
	padding: ${SPACERS.M};
	margin-top: ${SPACERS.M};
	margin-right: ${SPACERS.M};
	margin-left: ${SPACERS.M};
`
const ContentValuePropHeader = styled.h2`
	color: ${COLORS.BLACK};
	font-size: ${FONT_SIZES.H3};
	text-align: center;
	font-weight: 600;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
	${MQ.M} {
		font-size: 2rem;
	}
`
const CarouselHeader = styled.h2`
	color: ${COLORS.BLACK};
	margin-bottom: ${SPACERS.M};
	font-size: ${FONT_SIZES.H2};
	text-align: center;
	font-weight: 600;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
	${MQ.M} {
		font-size: 2rem;
	}
`
const ContentValuePropContent = styled(Box)`
	color: ${COLORS.BLACK};
	font-size: ${FONT_SIZES.H4};
	text-align: center;
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	width: 90%;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
	${MQ.M} {
		font-size: ${FONT_SIZES.H4};
	}
	${MQ.L} {
		font-size: ${FONT_SIZES.H3};
	}
`

function IndexPage({ data, location }) {
	const [dispensaries, setDispensaries] = useState([])
	const [strains, setStrains] = useState([])
	const strainCats = useRef([])
	useEffect(() => {
		if (data.allDispensariesJson) {
			setDispensaries(
				sortByKey(
					data.allDispensariesJson.edges.map(({ node }) => ({ ...node.dispensary })),
					'city'
				)
			)
		}
		if (data.allStrainsJson) {
			setStrains(data.allStrainsJson.edges.map(({ node }) => ({ ...node.strain })))
			strainCats.current = data.allStrainsJson.edges.map(({ node }) => node.strain.category)
		}
	}, [])
	return (
		<GlobalLayout
			isDisplayingSearch={false}
			location={location}
			isShowingBreadcrumbs={false}
			isShowingAccountLink={true}
			backgroundColor={COLORS.LIGHT_GRAY}
		>
			<Helmet>
				{/* Schema.org tags */}
				<script type="application/ld+json">
					{JSON.stringify({
						'@context': 'http://schema.org',
						'@type': 'WebSite',
						url: 'http://www.weedrater.com/',
						name: 'Weedrater',
						description: 'Real Time Menus for Marijuana Dispensaries',
					})}
				</script>
			</Helmet>
			<Hero />
			<ContentWrapper>
				<Box
					css={css`
						background: ${COLORS.WHITE};
						${MQ.L} {
							padding-top: ${SPACERS.XL};
							padding-bottom: ${SPACERS.XL};
							background: ${COLORS.WHITE};
							transition: background 0.5s;
							.dark & {
								background: ${COLORS.BLACK};
							}
						}
					`}
				>
					<Flex flexDirection={['column', 'row']} flexWrap={['wrap', 'nowrap']}>
						<ContentBoxCTA
							flexGrow={['auto', 1]}
							flexBasis={['auto', 0]}
							color={COLORS.WHITE}
							isHidden={[false, false]}
						>
							<ContentValueProp>
								<IconBox>
									<Link to="/dispensaries">
										<DispensaryLocator />
									</Link>
								</IconBox>
								<ContentValuePropHeader>Dispensary Locator</ContentValuePropHeader>
								<ContentValuePropContent>
									Find the closest and the best dispensary in your area
								</ContentValuePropContent>
								<ContentValuePropBox>
									<PrimaryButtonLink color={COLORS.RED} textcolor={COLORS.WHITE} to="/dispensaries">
										Locate Now
									</PrimaryButtonLink>
								</ContentValuePropBox>
							</ContentValueProp>
						</ContentBoxCTA>
						<ContentBoxCTA
							flexGrow={['auto', 1]}
							flexBasis={['auto', 0]}
							color={COLORS.WHITE}
							isHidden={[false, false]}
						>
							<ContentValueProp>
								<IconBox>
									<Link to="/strains">
										<StrainFinder />
									</Link>
								</IconBox>
								<ContentValuePropHeader>Strain Finder</ContentValuePropHeader>
								<ContentValuePropContent>
									Find strains that fit for your specific needs
								</ContentValuePropContent>
								<ContentValuePropBox>
									<PrimaryButtonLink to="/strains" color={COLORS.RED} textcolor={COLORS.WHITE}>
										Find Now
									</PrimaryButtonLink>
								</ContentValuePropBox>
							</ContentValueProp>
						</ContentBoxCTA>
						<ContentBoxCTA
							flexGrow={['auto', 1]}
							flexBasis={['auto', 0]}
							color={COLORS.WHITE}
							isHidden={[false, false]}
						>
							<ContentValueProp>
								<IconBox>
									<Link to="/dispensaries">
										<PriceTag />
									</Link>
								</IconBox>
								<ContentValuePropHeader>Special Offers</ContentValuePropHeader>
								<ContentValuePropContent>
									Here is a place for sub information to go along the welcome message
								</ContentValuePropContent>
								<ContentValuePropBox>
									<PrimaryButton color={COLORS.RED} textColor={COLORS.WHITE}>
										Save Now
									</PrimaryButton>
								</ContentValuePropBox>
							</ContentValueProp>
						</ContentBoxCTA>
					</Flex>
				</Box>
				<ContentBoxCTA boxShadow={false} alignSelf="center" color={COLORS.LIGHT_GRAY} isHidden={[false, false]}>
					<Breakpoint S down>
						<HomeCarousel defaultWait={5000} height={630} width={330}>
							{strains.map(strain => {
								return (
									<Slide right key={`disp_slide_${strain.id}`}>
										<StrainCarouselSlide strain={strain} />
									</Slide>
								)
							})}
						</HomeCarousel>
					</Breakpoint>
					<Breakpoint L up>
						<StrainCarouselLarge defaultWait={5000} height={550} dotLabels={strainCats.current}>
							{strains.map(strain => (
								<Fade key={`disp_slide_${strain.id}`}>
									<StrainCarouselSlideLarge strain={strain} />
								</Fade>
							))}
						</StrainCarouselLarge>
					</Breakpoint>
				</ContentBoxCTA>
				<ContentBoxCTA alignSelf={['center', 'inherit']} isHidden={[false, false]}>
					<CarouselHeader>Featured Dispensaries</CarouselHeader>
					<Breakpoint S down>
						<HomeCarousel defaultWait={5000} height={630} width={330}>
							{dispensaries.map(dispensary => {
								return (
									<Slide right key={`disp_slide_${dispensary.id}`}>
										<DispensaryCarouselSlide dispensary={dispensary} />
									</Slide>
								)
							})}
						</HomeCarousel>
					</Breakpoint>
					<Breakpoint M up>
						<Flex
							css={css`
								padding-bottom: ${SPACERS.M};
							`}
							justifyContent="space-around"
						>
							{dispensaries.map(dispensary => {
								return (
									<Box key={`disp_slide_${dispensary.id}`}>
										<DispensaryCarouselSlide dispensary={dispensary} />
									</Box>
								)
							})}
						</Flex>
					</Breakpoint>
				</ContentBoxCTA>
				<Box
					css={css`
						background: ${COLORS.GREEN};
						padding: ${SPACERS.L};
					`}
				>
					<Flex
						flexDirection={['column', 'row']}
						justifyContent={['center', 'space-around']}
						alignItems="center"
					>
						<Box
							css={css`
								padding-bottom: ${SPACERS.M};
								padding-right: ${SPACERS.M};
								padding-left: ${SPACERS.M};
							`}
						>
							<span
								css={css`
									font-size: ${FONT_SIZES.H3};
									color: ${COLORS.WHITE};
									${MQ.L} {
										font-size: ${FONT_SIZES.H1};
										font-weight: 600;
									}
								`}
							>
								Join our Growing Community!
							</span>
						</Box>
						<Box
							css={css`
								padding-bottom: ${SPACERS.M};
							`}
						>
							<Flex justifyContent="space-around">
								<Box
									css={css`
										margin: ${SPACERS.S};
									`}
								>
									<PrimaryButtonLink
										color={COLORS.RED}
										textcolor={COLORS.WHITE}
										to={'/register'}
										state={{ activeTabIndex: 2 }}
										fontSize={FONT_SIZES.H4}
										paddingx={'40px'}
									>
										Sign Up
									</PrimaryButtonLink>
								</Box>
								<Box
									css={css`
										margin: ${SPACERS.S};
									`}
								>
									<PrimaryButtonAnchor
										color={COLORS.BLACK}
										textcolor={COLORS.WHITE}
										href={'mailto:sales@weedrater.com'}
										state={{ activeTabIndex: 2 }}
										fontSize={FONT_SIZES.H4}
										paddingx={'40px'}
									>
										Advertise
									</PrimaryButtonAnchor>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</ContentWrapper>
		</GlobalLayout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
}

export default IndexPage

export const pageQuery = graphql`
	query HomePageQuery {
		allDispensariesJson(filter: { dispensary: { showInHome: { eq: true } } }) {
			edges {
				node {
					dispensary {
						showInHome
						id
						name
						imageUrl
						averageRating
						totalReviews
						address1
						address2
						city
						state
						slug
						zip
					}
				}
			}
		}
		allStrainsJson(filter: { strain: { showInHome: { eq: true } } }) {
			edges {
				node {
					strain {
						name
						id
						category
						averageRating
						totalReviews
						description
						slug
						cannabinoidProfile
						imageURL
					}
				}
			}
		}
	}
`
