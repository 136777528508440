import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import makeCarousel from 'react-reveal/makeCarousel'
import { Flex, Box } from '@rebass/emotion'
import { Container, Children, DotIcon, Dots, DotLabel } from './styles'

function StrainCarouselLargeUI({ position, total, handleClick, children, height, width, dotLabels = [] }) {
	return (
		<Container width={width} height={height} justifyContent="center" flexDirection="column">
			<Dots width={width} alignSelf="center">
				<Flex flexDirection="row" justifyContent="center">
					{Array(...Array(total)).map((val, index) => {
						return (
							<Box
								key={index}
								onClick={handleClick}
								data-position={index}
								index={index}
								position={position}
							>
								{<DotLabel>{dotLabels[index]}</DotLabel> || <DotIcon>{'●'}</DotIcon>}
							</Box>
						)
					})}
				</Flex>
			</Dots>
			<Children width={width} height={height}>
				{children}
			</Children>
		</Container>
	)
}
StrainCarouselLargeUI.propTypes = {
	position: PropTypes.number,
	total: PropTypes.number,
	handleClick: PropTypes.func,
	children: PropTypes.node,
	height: PropTypes.number,
	width: PropTypes.number,
	dotLabels: PropTypes.array,
}

const RevealCarousel = makeCarousel(StrainCarouselLargeUI)

function StrainCarouselLarge({ children, defaultWait = 2000, width = 1000, height = 460, dotLabels = [] }) {
	const [uiWidth, setUiWidth] = useState(0)
	const [uiHeight, setUiHeight] = useState(0)
	const [waitTime, setWaitTime] = useState(2000)
	useEffect(() => {
		setUiHeight(height)
		setUiWidth(width)
		setWaitTime(defaultWait)
	}, [width, height, defaultWait])
	return (
		<RevealCarousel height={uiHeight} width={uiWidth} defaultWait={waitTime} dotLabels={dotLabels}>
			{children}
		</RevealCarousel>
	)
}

StrainCarouselLarge.propTypes = {
	children: PropTypes.node,
	defaultWait: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	dotLabels: PropTypes.array,
}

export default StrainCarouselLarge
